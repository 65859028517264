<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0 root-container"
    >
      <b-row
        class="d-flex flex-row align-items-center justify-content-start px-3 my-2 ml-3 w-100 card-header-row"
        no-gutters
      >
        <h4 class="py-3">
          CSV Import
        </h4>
      </b-row>
      <!-- invoice summary row -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 px-1 py-1"
        no-gutters
      >
        <b-tabs
          active-nav-item-class="font-weight-bold text-main-green"
          class="invoice-tabs"
          nav-class="ml-3 tab-wrapper"
          content-class="mt-3"
          v-model="tabState"
        >
          <!-- table row -->
          <b-tab title="1. Select date" title-link-class="tab-nav-link" active>
            <SelectDate />
          </b-tab>
          <b-tab title="2. Select amounts" title-link-class="tab-nav-link">
            <SelectAmounts />
          </b-tab>
          <b-tab title="3. Select description" title-link-class="tab-nav-link">
            <SelectDescription />
          </b-tab>
          <b-tab title="4. Select type" title-link-class="tab-nav-link">
            <SelectType />
          </b-tab>
          <b-tab title="5. Select particulars" title-link-class="tab-nav-link">
            <SelectParticulars />
          </b-tab>
          <b-tab title="6. Select code" title-link-class="tab-nav-link">
            <SelectCode />
          </b-tab>
          <b-tab title="7. Select reference" title-link-class="tab-nav-link">
            <SelectReference />
          </b-tab>
          <b-tab title="8. Confirm import" title-link-class="tab-nav-link">
            <ConfirmImport />
          </b-tab>
        </b-tabs>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
import { mapGetters } from "vuex";
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import SelectDate from "@/components/Transactions/CSVImport/SelectDate";
// import SelectDateV2 from "@/components/Transactions/CSVImport/SelectDateV2";
import SelectAmounts from "@/components/Transactions/CSVImport/SelectAmounts";
import SelectDescription from "@/components/Transactions/CSVImport/SelectDescription";
import SelectType from "@/components/Transactions/CSVImport/SelectType";
import SelectParticulars from "@/components/Transactions/CSVImport/SelectParticulars";
import SelectCode from "@/components/Transactions/CSVImport/SelectCode";
import SelectReference from "@/components/Transactions/CSVImport/SelectReference";
import ConfirmImport from "@/components/Transactions/CSVImport/ConfirmImport";
// import FormInput from "@/components/Form/FormInput";
// import FormSelect from "@/components/Form/FormSelect";

// services
// import { GetAllTransactions } from "@/services/transactions.service";

export default {
  name: "CsvImport",
  components: {
    HomeLayout,
    SelectDate,
    // SelectDateV2,
    SelectAmounts,
    SelectDescription,
    SelectType,
    SelectParticulars,
    SelectCode,
    SelectReference,
    ConfirmImport,
    // FormInput,
    // FormSelect,
  },
  data() {
    return {
      // isEnable: false,
      accountType: null,
      bankStatementFile: null,
      accountTypeOptions: [],
    //   transactionsItems: [],
    };
  },
  watch: {
    // getIsEnable(val) {
    //   console.log("getIsEnable", val);
    //   this.isEnable = val;
    // }
  },
  computed: {
    ...mapGetters({
      getTabState: "general/getTabState",
      // getIsEnable: "transactions/getIsEnable",
      getBankStatementItems: "transactions/getBankStatementItems",
    }),
    tabState: {
      get() {
        return this.getTabState;
      },
    }
  },
  async mounted() {
    // console.log("getIsEnable", this.getIsEnable);
    // this.isEnable = this.getIsEnable;
    // await this.initFn();
  },
  methods: {
  },
};
</script>

<style scoped>
.root-container {
  min-height: 650px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
}
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
.root-container >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}
.root-container >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}
.root-container >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 15px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}
.root-container >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: #0B9B7D;
}
.root-container >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 3px solid #0B9B7D;
}
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: #0B9B7D;
}
.table-card-row .filter-dropdown,
.table-card-row .export-dropdown {
  height: 40px;
  min-width: 330px;
  max-width: 350px;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}
.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 8px;
  height: 8px;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
.table-card-row .export-dropdown >>> .export-dropdown-button {
  padding: 6px 20px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .insight-cards-row {
    height: 350px;
  }
  .insight-cards-row .filter-dropdown {
    min-width: 35px;
  }
  .insight-cards-row .filter-dropdown >>> .add-customer-btn {
    height: 50px;
    padding-right: 100px;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }
  .insight-cards-row .filter-dropdown {
    height: 200px;
    min-width: auto;
  }
  .insight-cards-row .filter-dropdown >>> .add-customer-btn {
    /* height: 50px; */
    /* padding-left: 100px */
  }
  .table-card-row .search-input-group {
    max-width: none;
  }
}
</style>
